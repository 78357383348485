import { Modal, Button } from "react-bootstrap";

function LocationNoticeModal({ business, show, handleOk }) {
  return (
    <Modal className="static-modal" show={show}>
      <Modal.Header>
        <Modal.Title>{business?.name} needs your location</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <span>
          {business?.name} would like to check that you are close enough to use Waitlist check-in.{" "}
          <strong> Please allow location services if prompted.</strong>
        </span>
      </Modal.Body>

      <Modal.Footer>
        <Button bsStyle="primary" onClick={handleOk}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LocationNoticeModal;
