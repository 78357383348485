import "./AddGuests.scss";
import distance from "../../imgs/distance.png";
import locationDenied from "../../imgs/locationDenied.png";

function CheckInBlocked({ business, appearance, denied }) {
  var buttonStyle = {
    backgroundColor: appearance.highlightBackgroundColor,
    color: appearance.highlightFontColor,
    width: "200px",
    fontFamily: appearance.fontFamily
  };
  if (denied) {
    return (
      <div className="checkin-error-message">
        You previously denied {business.name} access to your location. You will need to
        <strong> go to your browser settings and allow location access</strong> to use Waitlist check-in.
        <div style={{ padding: "40px" }}>
          <img src={locationDenied} alt="Location access denied" />
        </div>
        <button className="btn btn-default" style={buttonStyle} onClick={() => location.reload()}>
          I've update my settings
        </button>
      </div>
    );
  } else {
    return (
      <div className="checkin-error-message">
        You are too far from {business.name} to use self check-in. Please move closer and tap reload.
        <div style={{ padding: "40px" }}>
          <img src={distance} alt="Too Far Away Image" />
        </div>
        <button className="btn btn-default" style={buttonStyle} onClick={() => location.reload()}>
          Reload
        </button>
      </div>
    );
  }
}

export default CheckInBlocked;
